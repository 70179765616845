import './AirlyWidget.css';
import React, {useState} from 'react';
import {ButtonGroup} from "@mui/material";
import {Button, Collapse} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";


export default function AirlyWidget(props) {
    const {t} = useTranslation();
    const [isAirlyWidgetOpen, setIsAirlyWidgetOpen] = useState(props.opened);

    return (<>
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-header bg-primary">
                        <div className="row">
                            <div className="col-6">
                                <h4 className="card-title text-white"
                                    style={{margin: "6px 0px 0px 0px"}}>
                                    {t("Air quality")}
                                </h4>
                            </div>
                            <div
                                className="col text-end"
                                style={{padding: "0px 0px 0px 0px"}}>
                                <ButtonGroup>
                                    <Button
                                        className="btn btn-primary"
                                        onClick={() => setIsAirlyWidgetOpen(!isAirlyWidgetOpen)}
                                        aria-controls="collapsable-pedestrian-card"
                                        aria-expanded={isAirlyWidgetOpen}>

                                        {isAirlyWidgetOpen && <FontAwesomeIcon icon={faMinus}/>}
                                        {!isAirlyWidgetOpen && <FontAwesomeIcon icon={faPlus}/>}
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </div>

                    </div>
                    <Collapse in={isAirlyWidgetOpen}>
                        <div id="collapsable-pedestrian-card">
                            <div className="card-body">
                                <>
                                    <script
                                        src={"https://airly.org/map/airly.js"}
                                        type="text/javascript"/>

                                    <iframe id="airly_1773775892"
                                            src="https://airly.org/widget/v2/?width=280&height=640&displayMeasurements=true&displayCAQI=true&autoWidth=true&language=en&indexType=AIRLY_CAQI&unitSpeed=si&unitTemperature=celsius&latitude=58.054321&longitude=27.055123&locationId=64042"
                                            style={{width: "100%", height: "370px", border: "none"}}/>

                                </>
                            </div>
                        </div>
                    </Collapse>
                </div>
            </div>
        </div>
    </>)
}
