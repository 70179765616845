import React, {useEffect, useState} from 'react';
import './Sensors.css';
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faMinus, faPlus, faRotate} from "@fortawesome/free-solid-svg-icons";
import {useNavigate, useParams} from 'react-router-dom';
import GetDeviceQuery from "../../queries/Devices/GetDevice";
import {ButtonGroup} from "@mui/material";
import {Button, Collapse} from "react-bootstrap";
import StatisticsCard from "../../components/StatisticsCard/StatisticsCard";
import GetSensorsQuery from "../../queries/Statistics/GetSensors";
import ReactApexChart from "react-apexcharts";
import ApexCharts from "apexcharts";

export default function Sensors() {
    const [deviceName, setDeviceName] = useState("");
    const [isSensorCardOpen, setIsSensorCardOpen] = useState(true);
    const [isSensorDataLoaded, setIsSensorDataLoaded] = useState(false);

    const currentGroup = JSON.parse(localStorage.getItem("current_group")).id;

    const {t} = useTranslation();
    const navigate = useNavigate();
    const params = useParams();

    const deviceId = params.deviceID;

    const [sensorBoxes] = useState({
        'road_temp': {'selected': true},
        'a_internal_temp': {'selected': true},
        'a_humidity': {'selected': true},
        'b_internal_temp': {'selected': true},
        'b_humidity': {'selected': true},
    });

    const [sensorData, setSensorData] = useState({
        'road_temp': 0,
        'a_internal_temp': 0,
        'a_humidity': 0,
        'b_internal_temp': 0,
        'b_humidity': 0,
        "a_orno_amperage": [],
        "a_orno_voltage": [],
        "a_orno_kwh": [],
        "b_orno_amperage": [],
        "b_orno_voltage": [],
        "b_orno_kwh": []
    });

    const [AOrnoChartOptions] = useState({
        chart: {
            id: 'orno-a-line-chart', type: 'area', stacked: false, height: 350, zoom: {
                type: 'x', enabled: true, autoScaleYaxis: false
            }, toolbar: {
                autoSelected: 'zoom'
            }
        }, stroke: {
            curve: 'straight',
        }, dataLabels: {
            enabled: false
        }, markers: {
            size: 0,
        },

        fill: {
            type: 'gradient', gradient: {
                shadeIntensity: 1, inverseColors: false, opacityFrom: 0.5, opacityTo: 0, stops: [0, 90, 100]
            },
        }, yaxis: {
            labels: {
                formatter: function (val) {
                    return (val).toFixed(0);
                },
            }, title: {
                text: 'Count'
            },
        }, xaxis: {
            type: 'datetime',
        }, tooltip: {
            shared: false, y: {
                formatter: function (val) {
                    return val
                }
            }
        }
    });

    const [BOrnoChartOptions] = useState({
        chart: {
            id: 'orno-b-line-chart', type: 'area', stacked: false, height: 350, zoom: {
                type: 'x', enabled: true, autoScaleYaxis: false
            }, toolbar: {
                autoSelected: 'zoom'
            }
        }, stroke: {
            curve: 'straight',
        }, dataLabels: {
            enabled: false
        }, markers: {
            size: 0,
        },

        fill: {
            type: 'gradient', gradient: {
                shadeIntensity: 1, inverseColors: false, opacityFrom: 0.5, opacityTo: 0, stops: [0, 90, 100]
            },
        }, yaxis: {
            labels: {
                formatter: function (val) {
                    return (val).toFixed(0);
                },
            }, title: {
                text: 'Count'
            },
        }, xaxis: {
            type: 'datetime',
        }, tooltip: {
            shared: false, y: {
                formatter: function (val) {
                    return val
                }
            }
        }
    });

    const [ornoChartASeries, setOrnoChartASeries] = useState([]);
    const [ornoChartBSeries, setOrnoChartBSeries] = useState([]);

    const fetchSensors = (id) => {
        setIsSensorDataLoaded(() => false);

        GetSensorsQuery(id).then((result) => {
            setSensorData((prev) => {
                return (
                    {
                        ...prev,
                        ...result.data.data
                    }
                )
            })
            setIsSensorDataLoaded(() => true);
        });
    }

    const fetchDeviceData = (id, group) => {
        GetDeviceQuery(id, group).then((result) => {
            setDeviceName(result.data.data.name);
        });
    }

    useEffect(() => {
        fetchDeviceData(deviceId, currentGroup);
        fetchSensors(deviceId);
    }, [deviceId, currentGroup]);

    useEffect(() => {
        if (isSensorDataLoaded) {
            let ornoAChartSeriesHolder = [];


            ornoAChartSeriesHolder.push({
                name: "Amperage", data: sensorData.a_orno_amperage
            })
            ornoAChartSeriesHolder.push({
                name: "Voltage", data: sensorData.a_orno_voltage
            })
            ornoAChartSeriesHolder.push({
                name: "KW/h", data: sensorData.a_orno_kwh
            })

            let chart = ApexCharts.getChartByID("orno-a-line-chart");


            chart.updateSeries(ornoAChartSeriesHolder).then(() => {
                setOrnoChartASeries(() => ornoAChartSeriesHolder);
            });

            let ornoBChartSeriesHolder = [];


            ornoBChartSeriesHolder.push({
                name: "Amperage", data: sensorData.b_orno_amperage
            })
            ornoBChartSeriesHolder.push({
                name: "Voltage", data: sensorData.b_orno_voltage
            })
            ornoBChartSeriesHolder.push({
                name: "KW/h", data: sensorData.b_orno_kwh
            })

            chart = ApexCharts.getChartByID("orno-b-line-chart");


            chart.updateSeries(ornoBChartSeriesHolder).then(() => {
                setOrnoChartBSeries(() => ornoBChartSeriesHolder);
            });

        }
    }, [isSensorDataLoaded]);

    return (<>
        <div className="row">
            <div className="col-sm-12">
                <div className="page-title-box">
                    <h4 className="page-title">
                        <a className='back-text' style={{textDecoration: "none"}} href="/"
                           onClick={() => navigate(-1)}>
                            <FontAwesomeIcon className='back-button' icon={faChevronLeft}/>
                            &nbsp; {t("Back")}
                        </a>

                        <a className='back-text' style={{textDecoration: "none", marginLeft: "40%"}} href="/">
                            {deviceName}
                        </a>
                    </h4>

                </div>
            </div>
        </div>


        <>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header bg-primary">
                            <div className="row">
                                <div className="col-6">
                                    <h4 className="card-title text-white"
                                        style={{margin: "6px 0px 0px 0px"}}>
                                        {t("Sensor data")}
                                    </h4>
                                </div>
                                <div className="col text-end" style={{padding: "0px 0px 0px 0px"}}>
                                    <ButtonGroup>
                                        <Button
                                            className="btn btn-primary"
                                            onClick={() => setIsSensorCardOpen(!isSensorCardOpen)}
                                            aria-controls="collapsable-pedestrian-card"
                                            aria-expanded={isSensorCardOpen}
                                        >
                                            {isSensorCardOpen && <FontAwesomeIcon icon={faMinus}/>}
                                            {!isSensorCardOpen && <FontAwesomeIcon icon={faPlus}/>}
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </div>

                        </div>
                        <Collapse in={isSensorCardOpen}>
                            <div id="collapsable-pedestrian-card">
                                {isSensorDataLoaded && <div className="card-body">

                                    <div className="row text-center">
                                        <div className="col-12">
                                            <StatisticsCard title={t("Road temperature")}
                                                            number={sensorData.road_temp + "°C"}
                                                            settings={sensorBoxes.road_temp}/>

                                        </div>

                                    </div>

                                    <div className="row text-center">
                                        <div className="col-3">
                                            <StatisticsCard title={t("A Internal temperature")}
                                                            number={sensorData.a_internal_temp + "°C"}
                                                            settings={sensorBoxes.a_internal_temp}/>

                                        </div>
                                        <div className="col-3">
                                            <StatisticsCard title={t("B Internal temperature")}
                                                            number={sensorData.b_internal_temp + "°C"}
                                                            settings={sensorBoxes.b_internal_temp}/>

                                        </div>
                                        <div className="col-3">
                                            <StatisticsCard title={t("A Humidity")}
                                                            number={sensorData.a_humidity + "%"}
                                                            settings={sensorBoxes.a_humidity}/>

                                        </div>
                                        <div className="col-3">
                                            <StatisticsCard title={t("A Humidity")}
                                                            number={sensorData.b_humidity + "%"}
                                                            settings={sensorBoxes.b_humidity}/>

                                        </div>

                                    </div>
                                    <div className="row" style={{marginTop: "20px"}}>
                                        <div className="col-12">
                                            <h4>{t("A Power data")}</h4>
                                            <ReactApexChart options={AOrnoChartOptions}
                                                            series={ornoChartASeries} type="area"
                                                            height={350}/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <h4>{t("B Power data")}</h4>
                                            <ReactApexChart options={BOrnoChartOptions}
                                                            series={ornoChartBSeries} type="area"
                                                            height={350}/>
                                        </div>
                                    </div>
                                </div>}

                                {!isSensorDataLoaded && <>
                                    <div className="card-body" style={{height: "200px"}}>

                                        <div className="row text-center">
                                            <div className="col-12">
                                                <FontAwesomeIcon icon={faRotate} size="10x" className="fa-spin"
                                                                 style={{color: "#4d79f6"}}/>
                                            </div>
                                        </div>
                                    </div>
                                </>}
                            </div>
                        </Collapse>
                    </div>
                </div>
            </div>
        </>
    </>);
}